import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import { apiCall } from 'CommonFunctions';
import { uploadVideoFile } from 'CommonFunctions';
import LoadingSpinner from 'Assets/images/loading.gif';
import { TableVideoList } from '../components/tableVideoList.js';

export default class RestrictedList extends Component {

  state = {
    uploadLoading: false,
    uploadProgressPerc: 0,
    videoList: null,
    origVideoList: null,
  }

  componentWillMount() {
    this.loadVideos();
  }

  async uploadVideoFile(evt) {
    this.setState({ uploadLoading: true });
    await uploadVideoFile(evt, this.uploadProgress);
    this.setState({ uploadLoading: false });
  }

  loadVideos = async () => {
    this.setState({ videoList: null, origVideoList: null });
    const x = await apiCall('getVideoList');
    this.setState({ videoList: x.results, origVideoList: x.results });
  }

  uploadProgress = (perc) => {
    this.setState({ uploadProgressPerc: perc });
  }

  filterList(text) {
    const tmp = this.state.origVideoList.filter(function(item) {
      return item.video.toLowerCase().search(text.toLowerCase()) >= 0;
    });
    //If input is empty then set state back to full list
    this.setState({ videoList: text === '' ? this.state.origVideoList : tmp });
  }

  render() {
      if (this.state.uploadLoading || this.state.videoList === null) {
        return (
          <div style={{ borderWidth: 1, borderStyle: 'solid', width: 600, minHeight: 600, borderRadius: 5, padding: 10, justifyContent: 'flex-start' }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: 150}}>
              <img src={LoadingSpinner} style={{ width: '6rem', marginBottom: 20 }} alt="loading..." />
              {this.state.uploadLoading &&
                <span>{this.state.uploadProgressPerc}%</span>
              }
            </div>
          </div> 
        );
      }

      return (
        <div style={{ borderWidth: 1, borderStyle: 'solid', width: 600, minHeight: 600, borderRadius: 5, padding: 10, justifyContent: 'flex-start' }}>

          <div style={{ marginBottom: 5 }}>Video Section</div>

            {this.state.videoList !== null &&
              <div>

                  <input
                      type="file"
                      accept='video/*'
                      onChange={(evt) => this.uploadVideoFile(evt)}
                  />

                <Button variant="contained" color="primary" style={{marginBottom: 5, marginTop: 5}} onClick={()=>this.loadVideos()}>Refresh</Button>

                <div style={{ width: 300, height: 70, padding: 10, marginBottom: 10 }}>
                <span style={{ fontSize: 13 }}>{'Search Video'}</span>
                <Input
                    onChange={(e) => this.filterList(e.target.value)}
                    style={{ fontSize: 12 }}
                    autoComplete='none'
                />
                </div>

                <TableVideoList data={this.state.videoList} rowPressed={()=>console.log('row pressed')}/>

              </div>
          }
          </div>
      )
  }
}
